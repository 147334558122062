import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Heading, Image } from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";

const KSSGalleryImg = () => {
  const routeParams = useParams();
  const navigate = useNavigate();
  console.log("routeParams....", routeParams);

  const [ActiveArray, setActiveArray] = useState([]);

  const eSports = Array.from({ length: 14 }, (_, i) => ({
    img: `/img/Esports/IMG-20250204-WA${String(9 + i).padStart(4, "0")}.jpg`,
  }));
  const Rugby = Array.from({ length: 18 }, (_, i) => ({
    img: `/img/68thNationalSchoolGameRugby/IMG-20250203-WA${String(37 + i).padStart(4, "0")}.jpg`,
  }));
  const Cycling = [
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0022.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0023.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0024.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0025.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0026.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0027.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0028.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0029.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0030.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0031.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0032.jpg" },
    { img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0033.jpg" },

  ]

  const KSS2023atGyanBhawan = [
    { img: "/img/KSS-2023/DSC_3807.jpg" },
    { img: "/img/KSS-2023/DSC_3808.jpg" },
    { img: "/img/KSS-2023/DSC_3815.jpg" },
    { img: "/img/KSS-2023/DSC_3832.jpg" },
    { img: "/img/KSS-2023/DSC_3505.jpg" },
    { img: "/img/KSS-2023/DSC_3511.jpg" },
    { img: "/img/KSS-2023/DSC_3513.jpg" },
    { img: "/img/KSS-2023/DSC_3516.jpg" },
    { img: "/img/KSS-2023/DSC_3517.jpg" },
    { img: "/img/KSS-2023/DSC_3518.jpg" },
    { img: "/img/KSS-2023/DSC_3527.jpg" },
    { img: "/img/KSS-2023/DSC_3528.jpg" },
    { img: "/img/KSS-2023/DSC_3530.jpg" },
    { img: "/img/KSS-2023/DSC_3537.jpg" },
    { img: "/img/KSS-2023/DSC_3540.jpg" },
    { img: "/img/KSS-2023/DSC_3542.jpg" },
    { img: "/img/KSS-2023/DSC_3548.jpg" },
    { img: "/img/KSS-2023/DSC_3552.jpg" },
    { img: "/img/KSS-2023/DSC_3561.jpg" },
    { img: "/img/KSS-2023/DSC_3562.jpg" },
    { img: "/img/KSS-2023/DSC_3564.jpg" },
    { img: "/img/KSS-2023/DSC_3565.jpg" },
    { img: "/img/KSS-2023/DSC_3567.jpg" },
    { img: "/img/KSS-2023/DSC_3572.jpg" },
    { img: "/img/KSS-2023/DSC_3576.jpg" },
    { img: "/img/KSS-2023/DSC_3626.jpg" },
    { img: "/img/KSS-2023/DSC_3646.jpg" },
    { img: "/img/KSS-2023/DSC_3653.jpg" },
    { img: "/img/KSS-2023/DSC_3657.jpg" },
    { img: "/img/KSS-2023/DSC_3661.jpg" },
    { img: "/img/KSS-2023/DSC_3664.jpg" },
    { img: "/img/KSS-2023/DSC_3674.jpg" },
  ];

  const BiharStateEsportsOpenChampionship = [
    { img: "/img/1stBiharSEOc/1stbSEoC.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC2.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC3.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC4.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC5.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC6.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC7.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC8.jpg" },
    { img: "/img/1stBiharSEOc/1stbSEoC9.jpg" },
  ];

  const NationalSchoolChessChampionship2024 = [
    { img: "/img/12thNS-CHES24/P1383941.jpg" },
    { img: "/img/12thNS-CHES24/P1383956.jpg" },
    { img: "/img/12thNS-CHES24/P1383986.jpg" },
    { img: "/img/12thNS-CHES24/P1383996.jpg" },
    { img: "/img/12thNS-CHES24/P1384018.jpg" },
    { img: "/img/12thNS-CHES24/P1384156.jpg" },
    { img: "/img/12thNS-CHES24/P1384159.jpg" },
    { img: "/img/12thNS-CHES24/P1384163.jpg" },
    { img: "/img/12thNS-CHES24/P1384180.jpg" },
    { img: "/img/12thNS-CHES24/P1384666.jpg" },
    { img: "/img/12thNS-CHES24/P1384732.jpg" },
    { img: "/img/12thNS-CHES24/P1384873.jpg" },


  ];

  const BiharSchoolChessatBSSA = [
    { img: "/img/Bihar-School-Chess/DSC_1538.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1543.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1566.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1576.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1565.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1652.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1657.jpg" },
    { img: "/img/Bihar-School-Chess/DSC_1658.jpg" },
  ];
  const BiharWomenKabaddiLeague = [
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00221.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00222.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00227.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00259.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00481.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00502.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00504.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00506.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00512.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00517.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00808.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00809.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN00815.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN05753.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN05754.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN05857.jpg" },
    { img: "/img/Bihar-Women-Kabaddi-League/JBN05874.jpg" },
  ]
  const Conclave2 = [
    { img: "/img/Conclave2.0/DSC_8220.jpg" },
    { img: "/img/Conclave2.0/DSC_8225.jpg" },
    { img: "/img/Conclave2.0/DSC_8291.jpg" },
    { img: "/img/Conclave2.0/DSC_8323.jpg" },
    { img: "/img/Conclave2.0/DSC_8345.jpg" },
    { img: "/img/Conclave2.0/DSC_8348.jpg" },
    { img: "/img/Conclave2.0/DSC_8352.jpg" },
    { img: "/img/Conclave2.0/DSC_8355.jpg" },

    { img: "/img/Conclave2.0/DSC_8373.jpg" },
    { img: "/img/Conclave2.0/DSC_8374.jpg" },
    { img: "/img/Conclave2.0/DSC_8220.jpg" },
    { img: "/img/Conclave2.0/DSC_8380.jpg" },
    { img: "/img/Conclave2.0/DSC_8385.jpg" },
    { img: "/img/Conclave2.0/DSC_8390.jpg" },
    { img: "/img/Conclave2.0/DSC_8408.jpg" },
    { img: "/img/Conclave2.0/DSC_8414.jpg" },
    { img: "/img/Conclave2.0/DSC_8436.jpg" },
    { img: "/img/Conclave2.0/DSC_8437.jpg" },
    { img: "/img/Conclave2.0/DSC_8440.jpg" },
    { img: "/img/Conclave2.0/DSC_8442.jpg" },

    { img: "/img/Conclave2.0/DSC_8445.jpg" },
    { img: "/img/Conclave2.0/DSC_8500.jpg" },
    { img: "/img/Conclave2.0/DSC_8524.jpg" },
    { img: "/img/Conclave2.0/DSC_8577.jpg" },
    { img: "/img/Conclave2.0/DSC_8583.jpg" },
    { img: "/img/Conclave2.0/DSC_8599.jpg" },
    { img: "/img/Conclave2.0/DSC_8622.jpg" },
    { img: "/img/Conclave2.0/DSC_8652.jpg" },
    { img: "/img/Conclave2.0/DSC_8654.jpg" },
    { img: "/img/Conclave2.0/DSC_8659.jpg" },
    { img: "/img/Conclave2.0/DSC_8808.jpg" },
    { img: "/img/Conclave2.0/DSC_8854.jpg" },
    { img: "/img/Conclave2.0/DSC_8213.jpg" },
    { img: "/img/Conclave2.0/DSC_8659.jpg" },
    { img: "/img/Conclave2.0/DSC_8224.jpg" },
    { img: "/img/Conclave2.0/DSC_8854.jpg" },
  ]
  const DashrathManjhi = [
    { img: "/img/DashrathManjhi/DSC_6890.jpg" },
    { img: "/img/DashrathManjhi/DSC_6911.jpg" },
    { img: "/img/DashrathManjhi/DSC_6915.jpg" },
    { img: "/img/DashrathManjhi/DSC_6924.jpg" },
    { img: "/img/DashrathManjhi/DSC_6968.jpg" },
    { img: "/img/DashrathManjhi/DSC_6676.jpg" },
    { img: "/img/DashrathManjhi/DSC_6679.jpg" },
    { img: "/img/DashrathManjhi/DSC_6681.jpg" },
    { img: "/img/DashrathManjhi/DSC_6685.jpg" },
    { img: "/img/DashrathManjhi/DSC_6686.jpg" },
    { img: "/img/DashrathManjhi/DSC_6692.jpg" },
    { img: "/img/DashrathManjhi/DSC_6693.jpg" },
    { img: "/img/DashrathManjhi/DSC_6711.jpg" },
    { img: "/img/DashrathManjhi/DSC_6712.jpg" },
    { img: "/img/DashrathManjhi/DSC_6719.jpg" },
    { img: "/img/DashrathManjhi/DSC_6765.jpg" },
    { img: "/img/DashrathManjhi/DSC_6766.jpg" },
    { img: "/img/DashrathManjhi/DSC_6803.jpg" },
    { img: "/img/DashrathManjhi/DSC_6845.jpg" },
    { img: "/img/DashrathManjhi/DSC_6850.jpg" },
    { img: "/img/DashrathManjhi/DSC_6858.jpg" },
    { img: "/img/DashrathManjhi/DSC_6878.jpg" },
    { img: "/img/DashrathManjhi/DSC_6886.jpg" },

  ]
  const BiharVolleyballLeague2024 = [
    { img: "/img/BiharVolleyballLeague2024/DSC00350.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00358.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00359.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00360.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00361.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00363.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00372.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00373.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC00383.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02436.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02445.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02446.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02447.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02457.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02461.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02467.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02469.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02473.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02477.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02478.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02481.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02521.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02524.jpg" },
    { img: "/img/BiharVolleyballLeague2024/DSC02527.jpg" },
  ]

  const BiharWomensAsianChampionsTrophyRajgir2024 = [
    { img: "/img/BWACTRajgir2024/ADIM19600.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM19649.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM19666.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM21686.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM21988.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM36811.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM24179.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM24210.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM25484.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM27120.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM28005.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM28052.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM28955.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM30813.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM30852.jpg" },

    { img: "/img/BWACTRajgir2024/ADIM35883.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM36811.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM44052.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM44391.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM45608.jpg" },
    { img: "/img/BWACTRajgir2024/ADIM19600.JPG" },
    { img: "/img/BWACTRajgir2024/ADIM45653.jpg" },

  ]

  useEffect(() => {
    switch (routeParams.id) {
      case "Bihar State Open Esports Championship 2024":
        setActiveArray(eSports);
        break;
      case "68th National School Game Rugby":
        setActiveArray(Rugby);
        break;
      case "68th National School Games Cycling (Road)":
        setActiveArray(Cycling);
        break;
      case "KSS-2023 at Gyan Bhawan":
        setActiveArray(KSS2023atGyanBhawan);
        break;
      case "1st Bihar State Esports Open Championship":
        setActiveArray(BiharStateEsportsOpenChampionship);
        break;
      case "12th National School Chess Championship- 2024":
        setActiveArray(NationalSchoolChessChampionship2024);
        break;
      case "Bihar School Chess at BSSA":
        setActiveArray(BiharSchoolChessatBSSA);
        break;
      case "Bihar Women Kabaddi League":
        setActiveArray(BiharWomenKabaddiLeague);
        break;
      case "Conclave 2.0":
        setActiveArray(Conclave2);
        break;
      case "Dashrath Manjhi":
        setActiveArray(DashrathManjhi)
        break;
      case "Bihar Volleyball League 2024":
        setActiveArray(BiharVolleyballLeague2024)
        break;
      case "Bihar Women's Asian Champions Trophy Rajgir 2024":
        setActiveArray(BiharWomensAsianChampionsTrophyRajgir2024)
        break;
      default:
        setActiveArray([]);
        break;
    }
  }, [routeParams.id]);
  // Redirect to GalleryPage if the page is refreshed
  // useEffect(() => {
  //   if (performance.getEntriesByType("navigation")[0].type === "reload") {
  //     navigate("/"); // Replace with your desired route
  //   }
  // }, [navigate]);

  return (
    <div>
      <Breadcrumb
        pageName="Gallery"
        pageTitle="gallery"
        id="#gallery"
        img_url="../../img/Gallery-desktop.jpg"
        mobile_banner="../../img/Gallery-mobile.jpg"
      />
      <Heading p={5} fontSize="50px">
        तस्वीरें
      </Heading>
      <div
        style={{
          height: "4px",
          width: "100%",
          backgroundColor: "rgb(8, 44, 44)",
          paddingBottom: "13px",
        }}
      ></div>
      <div className="container">
        <Row className="justify-content-center">
          {ActiveArray.map((item, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="mb-4 "
              style={{ marginTop: "15px" }}
            >
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "100%", marginBottom: "10px" }}
              >
                <Box
                  position="relative"
                  width="100%"
                  paddingBottom="75%" // Creates a 4:3 aspect ratio
                  overflow="hidden"
                >
                  <Image
                    src={item.img}
                    alt={item.name}
                    className="aspect-ratio-item"
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    transition="transform 0.5s ease"
                    _hover={{ transform: 'scale(1.2)' }}
                  />
                </Box>
                {/* <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    paddingBottom: '56.25%', // This is for a 16:9 aspect ratio. Adjust as needed.
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div> */}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default KSSGalleryImg;
